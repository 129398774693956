@font-face {
  font-family: "RobotoRegular";   /*Can be any text*/
  src: local("RobotoCondensedRegular"),
    url("./assets/fonts/RobotoCondensed-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoBold";   /*Can be any text*/
  src: local("RobotoCondensedBold"),
    url("./assets/fonts/RobotoCondensed-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "PTSans";   /*Can be any text*/
  src: local("PTSansBold"),
    url("./assets/fonts/PTSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "OswaldRegular";   /*Can be any text*/
  src: local("OswaldRegular"),
    url("./assets/fonts/Oswald-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "SalsaRegular";   /*Can be any text*/
  src: local("SalsaRegular"),
    url("./assets/fonts/Salsa-Regular.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.image-container {
  position: relative; /* Needed to position the overlay */
  width: 100%; /* Set desired width or make it responsive */
  height: 800px; /* Set desired height */
  border-bottom-left-radius: 50px; /* Adjust as needed */
  border-bottom-right-radius: 50px;
  clip-path: ellipse(85% 100% at 50% 0%); 
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* This will cover the container, you can use 'contain' to fit inside */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(6, 6, 14, 0.8); /* Dark blue with opacity */
   /* Allows clicks to pass through to the image below */
  z-index: 100;
}

.color-change-button:hover {
  background-color: #FA0468; /* Color when hovered */
}

.countdown {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-shadow: 0px 4px 8px rgba(69, 53, 53, 0.1);
  background-color: white;
}

.countdown-item {
  background-color: #fff; /* Example background color */
  margin: 5px;
  padding: 10px;
  border-radius: 5px; /* Rounded corners */
  text-align: center;
}

.countdown-time {
  font-size: 30px; /* Large font size for the time part */
  font-weight: bold;
  margin-bottom: 5px;
}

.countdown-label {
  font-size: 15px; /* Smaller font size for labels (days, hours, etc.) */
  text-transform: capitalize; /* Capitalize labels */
}

.image-container1 {
  position: relative; /* Needed to position the overlay */
  width: 100%; /* Set desired width or make it responsive */
  height: 1000px; /* Set desired height */
}

.background-image1 {
  width: 100%;
  height: 100%;
  object-fit: cover; /* This will cover the container, you can use 'contain' to fit inside */
}

.overlay1 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(66, 78, 92, 0.8); /* Dark blue with opacity */
  /* Allows clicks to pass through to the image below */
}

.curved-bottom-div::before {
  content: "";
  background: linear-gradient(to right, transparent 48%, #424E5C 52%);
  z-index: 1;
}

.slider {
  position: relative;
  /* justify-content: center;
  align-items: center; */
  width: 100%;
  z-index: -100;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
